import React, { useRef, useState } from 'react';

import { isObject } from '@rippling/utils/validationUtils';
import { isNonEmptyArray } from '@rippling/utils/validationUtils';
import Image from 'components/basic/Image';

import { getObjectEntries } from ':helpers/object-helpers';
import SectionBuilder from ':templates/SectionBuilder';

const regionInputData = (country) => {
  const inputData = {};
  if (country === 'Netherlands') {
    inputData.label = 'Contract Duration';
    inputData.placeholder = 'Select a contract';
  } else if (country === 'Israel') {
    inputData.label = 'Seniority';
    inputData.placeholder = 'Select a seniority';
  } else {
    inputData.label = 'Region';
    inputData.placeholder = 'Select a region';
  }

  return inputData;
};

const enableCalculateButton = (stateVals, renderedValues, hasRegions) => {
  const allValuesProperlyFilled =
    stateVals.selectedCountry &&
    (hasRegions ? stateVals.selectedRegion : true) &&
    stateVals.selectedAnnualIncome >= 0;

  const valuesAreDifferent =
    stateVals.selectedCountry !== renderedValues.selectedCountry ||
    (hasRegions
      ? stateVals.selectedRegion !== renderedValues.selectedRegion
      : true) ||
    stateVals.selectedAnnualIncome !== renderedValues.selectedAnnualIncome;

  return allValuesProperlyFilled && valuesAreDifferent;
};

const slugToTitle = (str) => str.replaceAll('_', ' ');

const formatCost = (num, formIsComplete, country) => {
  return !formIsComplete || num === 0
    ? '-'
    : num.toLocaleString(commaPlacementLocale(country), {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
};

const getAnnualLineItemCost = ({
  flat,
  maximum,
  minimum,
  monthlyFlat,
  monthlyIncomeMaximum,
  monthlyMaximum,
  monthlyMinimum,
  percentage,
  selectedAnnualIncome,
}) => {
  const decimal = Number.parseFloat(percentage || 0) / 100;
  let totalCost = 0;
  if (flat || monthlyFlat) {
    return flat || monthlyFlat * 12;
  } else if (monthlyMaximum) {
    if (selectedAnnualIncome / 12 > monthlyMaximum) {
      totalCost = monthlyMaximum * decimal * 12;
    } else {
      totalCost = decimal * selectedAnnualIncome;
    }
  } else if (monthlyIncomeMaximum) {
    totalCost = decimal * selectedAnnualIncome;
  } else {
    totalCost = decimal * selectedAnnualIncome;
  }

  const monthlyIncomeMaximumOrMax = monthlyIncomeMaximum
    ? monthlyIncomeMaximum * decimal * 12
    : maximum;

  const min = monthlyMinimum ? monthlyMinimum * 12 : minimum;
  const max = monthlyMaximum ? monthlyMaximum * 12 : monthlyIncomeMaximumOrMax;

  if (max && totalCost > max) {
    return max;
  } else if (min && totalCost < min) {
    return min;
  } else {
    return totalCost;
  }
};

const getMonthlyLineItemCost = ({
  flat,
  maximum,
  minimum,
  monthlyFlat,
  monthlyIncomeMaximum,
  monthlyMaximum,
  monthlyMinimum,
  percentage,
  selectedAnnualIncome,
}) => {
  const decimal = Number.parseFloat(percentage || 0) / 100;
  let totalCost = 0;

  if (flat || monthlyFlat) {
    return flat ? flat / 12 : monthlyFlat;
  } else if (monthlyMaximum) {
    if (selectedAnnualIncome / 12 > monthlyMaximum) {
      totalCost = monthlyMaximum * decimal;
    } else {
      totalCost = decimal * selectedAnnualIncome / 12;
    }
  } else {
    totalCost = decimal * selectedAnnualIncome / 12;
  }
  const monthlyIncomeMaximumOrMax = monthlyIncomeMaximum
    ? monthlyIncomeMaximum * decimal
    : maximum / 12;

  const min = monthlyMinimum || minimum;
  const max = monthlyMaximum || monthlyIncomeMaximumOrMax;

  if (max && totalCost > max) {
    return max;
  } else if (min && totalCost < min) {
    return min;
  } else {
    return totalCost;
  }
};

const Caret = ({ color }) => (
  <svg
    fill="none"
    height="7"
    viewBox="0 0 12 7"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7.53033e-08 0.685191L0.708875 -5.11514e-08L6 5.56874L11.2911 7.50407e-08L12 0.685191L6 7L7.53033e-08 0.685191Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

const commaPlacementLocale = (country) => {
  if (country === 'India') {
    return 'en-IN';
  }

  return 'en-US';
};

const isLineItem = (obj) => {
  return (
    isObject(obj) &&
    Object.values(obj).every((item) =>
      Object.keys(item).includes('percentage') ||
        Object.keys(item).includes('flat') ||
        Object.keys(item).includes('monthly flat') ||
        Object.keys(item).includes('tier1') ||
        Object.keys(item).includes('tier2'))
  );
};

const EmployeeCostCalculator = (props) => {
  const { attributes } = props;
  const {
    data: rawData, form, isGated,
  } = attributes;

  const data = { ...rawData };
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedAnnualIncome, setSelectedAnnualIncome] = useState('-');

  const [showCalculator, setShowCalculator] = useState(true);
  const [formIsComplete, setFormIsComplete] = useState(false);

  const [renderedValues, setRenderedValues] = useState({
    currencySign: '',
    isAnnual: true,
    lineItems: [],
    selectedAnnualIncome: 0,
    selectedCurrency: { abbreviation: 'USD' },
  });

  const [selectedCurrency, setSelectedCurrency] = useState(rawData.Australia.meta);

  const [showLineItems, setShowLineItems] = useState(false);

  const currencySign = selectedCurrency?.symbol || '$';
  const countries = Object.keys(data).sort((a, b) => a - b);

  let regions = [];

  //let lineItems = [];
  const hasRegions = !isLineItem(data[selectedCountry]?.rates);

  if (data[selectedCountry]?.rates) {
    if (hasRegions) {
      regions = getObjectEntries(data[selectedCountry].rates);

      //lineItems =
      getObjectEntries(data[selectedCountry].rates[selectedRegion]) || [];
    } else {
      //lineItems = getObjectEntries(data[selectedCountry].rates);
    }
  }

  form[0].attributes = {
    ...form[0].attributes,
    customOnSuccess: () => {
      setShowCalculator(true);
      setFormIsComplete(true);
    },
  };

  const lineItems =
    getObjectEntries(data[selectedCountry]?.rates?.[selectedRegion] ||
        data[selectedCountry]?.rates) || [];

  const getLineItemTotals = (isAnnualFlag) => {
    const totals = lineItems.map((lineItem, currentIndex) => {
      const {
        flat,
        maximum,
        minimum,
        monthlyFlat = lineItem[1]['monthly flat'],
        monthlyIncomeMaximum = lineItem[1]['monthly maximum'],
        monthlyMaximum = lineItem[1]['monthly maximum'],
        monthlyMinimum = lineItem[1]['monthly minimum'],
        percentage,
      } = lineItem[1];

      const tiers = renderedValues?.lineItems?.[currentIndex]?.[1] ?
        getObjectEntries(renderedValues.lineItems[currentIndex][1]).filter((tier) => {
          return tier[0].includes('tier');
        }) : null;

      if (
        isNonEmptyArray(tiers)
      ) {
        let total = 0;

        for (const [index, tier] of tiers.entries()) {
          //const flat = tier[1].flat;
          //const maximum = tier[1].maximum;
          //const monthlyFlat = tier[1]['monthly flat'];
          const monthlyIncomeMaximum = tier[1]['monthly income maximum'];

          //const monthlyMaximum = tier[1]['monthly maximum'];
          const percentage = tier[1].percentage;
          const decimal = Number.parseFloat(percentage || 0) / 100;
          const lastTierMax = index - 1 >= 0 ? tiers[index - 1][1]['monthly income maximum'] : 0;
          const selectedMonthlyIncome = selectedAnnualIncome / 12;

          if (selectedMonthlyIncome <= monthlyIncomeMaximum) {
            if (selectedMonthlyIncome > lastTierMax) {
              total += (selectedMonthlyIncome - lastTierMax) * decimal;
            }
          } else {
            total += (monthlyIncomeMaximum - lastTierMax) * decimal;
          }
        }

        if (isAnnualFlag) {
          total *= 12;
        }

        return total;
      } else if (selectedCountry === 'United Kingdom' && renderedValues?.lineItems?.[currentIndex]?.[0] === 'National Insurance') {
        const decimal = Number.parseFloat(percentage || 0) / 100;
        const salaryTare = renderedValues.lineItems[currentIndex][1]['salary threshold'];
        const total = decimal * (selectedAnnualIncome - salaryTare * 12);

        return isAnnualFlag ? total : total / 12;
      } else if (isAnnualFlag) {
        return getAnnualLineItemCost({
          flat,
          maximum,
          minimum,
          monthlyFlat,
          monthlyIncomeMaximum,
          monthlyMaximum,
          monthlyMinimum,
          percentage,
          selectedAnnualIncome,
        });
      } else {
        return getMonthlyLineItemCost({
          flat,
          maximum,
          minimum,
          monthlyFlat,
          monthlyIncomeMaximum,
          monthlyMaximum,
          monthlyMinimum,
          percentage,
          selectedAnnualIncome,
        });
      }
    });

    return totals;
  };

  const getLineItemsTotal = (isAnnualFlag) => {
    const lineItemTotals = getLineItemTotals(isAnnualFlag);
    const total =
      lineItemTotals.length > 0 ? lineItemTotals.reduce((a, b) => a + b) : 0;

    return total;
  };

  const calcRenderedValues = (isAnnualFlag) => {
    const employerCosts = getLineItemsTotal(isAnnualFlag);

    const totalEmployerCosts = isAnnualFlag
      ? employerCosts + selectedAnnualIncome
      : employerCosts + selectedAnnualIncome / 12;

    setRenderedValues({
      currencySign,
      employerCosts: employerCosts,
      isAnnual: isAnnualFlag,
      lineItems: lineItems,
      lineItemsTotals: getLineItemTotals(isAnnualFlag),
      selectedAnnualIncome,
      selectedCountry,
      selectedCurrency,
      selectedRegion,
      totalEmployerCosts: totalEmployerCosts,
    });
  };

  const lineItemsToRender = renderedValues.lineItems || [];

  const [isHovered, setIsHovered] = useState(false);

  const resultsRef = useRef();

  return (
    <>
      <div className="h-72 md:pt-80 bg-tertiary-gray1 " />
      <div className="bg-tertiary-gray1 pb-24 sm:mb-40 md:pb-80">
        <div className="grid z-1000 flex flex-col md:gap-80 gap-24">
          <div
            className={`w-100% md:display-grid md:grid-cols-[42%_minmax(0,_1fr)] md:gap-120 md:pt-40 ${showCalculator && !formIsComplete ? 'pb-40 md:pb-0' : ''} ${!showCalculator && !formIsComplete ? 'pb-40 md:pb-0' : ''}`}
          >
            <div className="h-100% md:h-700 md:sticky md:top-120 py-40">
              <div className="flex h-100% flex-col justify-between relative  ">
                <div className="flex flex-col ">
                  <div className="pb-20 sm:pb-24 md:pb-32">
                    <h1 className="mb-8 font-semibold font-body text-primary-plum h3">
                      Employee Cost Calculator
                    </h1>
                    <p className="is-style-p1 text-primary-plum opacity-70">
                      Have somewhere great in mind for your next hire? Estimate
                      the cost of employment in different countries.
                    </p>
                  </div>

                  <div className="w-100%">
                    <div className="mb-16">
                      <label className="relative !bg-white rounded-4 block is-style-p1 !text-black mb-12">
                        <span className="absolute left-14 top-8 text-10 leading-15 z-1000 !opacity-70 !text-primary-plum">
                          Country of residence
                        </span>
                        <select
                          className={`leading-22 pt-24 pr-12 pb-8 pl-12 !text-primary-plum !rounded-4 focus:!border-primary-plum bg-white hover:!border-primary-plum !border-tertiary-gray2 transition ease-in duration-150 ${
                            selectedCountry ? '!opacity-100' : ''
                          }`}
                          onChange={(e) => {
                            setSelectedCountry(e.target.value);
                            setSelectedCurrency(data[e.target.value].meta);
                            setSelectedRegion(null);
                            setShowLineItems(false);
                            setRenderedValues({
                              currencySign: data[e.target.value].meta.symbol,
                              employerCosts: '',
                              isAnnual: renderedValues.isAnnual,
                              lineItems:
                                getObjectEntries(data[e.target.value]?.rates) ||
                                [],
                              lineItemsTotals: null,
                              selectedAnnualIncome,
                              selectedCountry: e.target.value,
                              selectedCurrency: data[e.target.value].meta,
                              selectedRegion: null,
                              totalEmployerCosts: '',
                            });
                          }}
                          value={selectedCountry}
                        >
                          <option className="" value="">
                            Select a country
                          </option>
                          {countries.map((country) => (
                            <option
                              key={`option-${country}`}
                              className=""
                              value={country}
                            >
                              {slugToTitle(country)}
                            </option>
                          ))}
                        </select>
                        <div className="absolute top-50% -translate-y-50% right-16 flex items-center h-100%">
                          <Caret color="#000" />
                        </div>
                        <div className="w-20 h-20 absolute top-50% -translate-y-50% right-16">
                          {data[selectedCountry]?.meta?.flag ? (
                            <Image
                              attributes={{ mediaURL: data[selectedCountry].meta.flag }}
                            />
                          ) : null}
                        </div>
                      </label>
                    </div>
                    {!!regions?.length && (
                      <div className="mb-16">
                        <label className="relative !bg-white rounded-4  block is-style-p1 !text-primary mb-12">
                          <span className="absolute left-14 top-8 text-10 z-1000 leading-15 !opacity-70 !text-primary-plum">
                            {regionInputData(selectedCountry).label}
                          </span>
                          <select
                            className="leading-22 pt-24 pr-12 pb-8 pl-12 !text-primary-plum bg-white !rounded-4 focus:!border-primary-plum hover:!border-primary-plum !border-tertiary-gray2 transition ease-in duration-150"
                            onChange={(e) => {
                              setSelectedRegion(e.target.value);
                            }}
                            value={selectedRegion}
                          >
                            <option className="" value="">
                              {regionInputData(selectedCountry).placeholder}
                            </option>
                            {regions.map(([region]) => (
                              <option key={`region-${region}`} value={region}>
                                {slugToTitle(region)}
                              </option>
                            ))}
                          </select>
                          <div className="absolute top-50% -translate-y-50% flex items-center right-16 h-100%">
                            <Caret color="#000" />
                          </div>
                        </label>
                      </div>
                    )}

                    <div className="mb-24">
                      <label className="relative block !bg-white is-style-p1 text-primary-plum rounded-4  mb-12">
                        <span className="absolute left-14 top-8 text-10 leading-15 z-1000 !opacity-70 !text-primary-plum">
                          Gross annual income
                        </span>
                        <input
                          className="leading-22 text-16 pt-24 pr-12 pb-8 pl-12 w-100% !text-primary-plum !rounded-4 border-1 bg-white focus:!border-primary-plum hover:!border-primary-plum !border-tertiary-gray2"
                          onChange={(e) => {
                            const val = Number.parseFloat(e.target.value.replaceAll(',', ''));

                            if (typeof val !== 'number') {
                              return '';
                            }

                            setSelectedAnnualIncome(Math.abs(Number.parseFloat(val || 0)));
                          }}
                          style={{ outline: 'none' }}
                          type="text"
                          value={selectedAnnualIncome.toLocaleString(
                            commaPlacementLocale(selectedCountry),
                            { maximumFractionDigits: 2 }
                          )}
                        />

                        <div className="absolute right-16 top-50% -translate-y-50%">
                          <p className="is-style-p2 text-black/50">
                            {selectedCountry
                              ? selectedCurrency.abbreviation
                              : null}
                          </p>
                        </div>
                      </label>
                    </div>

                    <input
                      className={`p-16 w-100% ${
                        !formIsComplete && !showCalculator
                      }`}
                      disabled={
                        !formIsComplete && !showCalculator ||
                        !enableCalculateButton(
                          {
                            selectedAnnualIncome,
                            selectedCountry,
                            selectedRegion,
                          },
                          renderedValues,
                          hasRegions
                        )
                      }
                      onClick={() => {
                        const isMobile = window.innerWidth < 1024;

                        if (isMobile) {
                          const paddingHeight = 80;

                          const getEleY = () =>
                            window.scrollY -
                            paddingHeight +
                            resultsRef.current.getBoundingClientRect().y;

                          window.scrollTo({
                            behavior: 'smooth',
                            left: 0,
                            top: getEleY(),
                          });
                        }

                        if (!formIsComplete) {
                          if (isGated) {
                            setShowCalculator(false);
                          } else {
                            setFormIsComplete(true);
                          }
                        }

                        calcRenderedValues(renderedValues.isAnnual);
                        setShowLineItems(true);
                        if (
                          selectedAnnualIncome &&
                          selectedCountry &&
                          selectedRegion
                        ) {
                          window.dataLayer.push({
                            event: 'rippling_employee_calc_submit',
                            selected_annual_income: selectedAnnualIncome,
                            selected_country: selectedCountry,
                            selected_region: selectedRegion,
                          });
                        } else {
                          window.dataLayer.push({
                            event: 'rippling_employee_calc_submit',
                            selected_annual_income: selectedAnnualIncome,
                            selected_country: selectedCountry,
                          });
                        }
                      }}
                      type="submit"
                      value="Calculate"
                    />
                  </div>
                </div>
                <div className="items-end flex pt-32 sm:pt-40 md:pt-0 ">
                  <div className="pt-32  border-t-1 border-tertiary-gray2 flex  gap-24">
                    <div className="bg-white h-56 w-56 flex items-center justify-center min-w-56  rounded-8 overflow-hidden">
                      <img src="https://rippling2.imgix.net/Info-16.svg" />
                    </div>
                    <div className="flex flex-col gap-4">
                      <p className="is-style-p2 font-medium text-plum-800 opacity-70">
                        Disclaimer
                      </p>
                      <p className="is-style-p2 text-plum-800 font-medium">
                        Employee costs are an estimate and may be subject to
                        changes based on updates to local tax legislations and
                        regimes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="md:static rounded-12 overflow-hidden md:pt-92 self-start"
              style={{ boxShadow: '2px 8px 12px rgba(0, 0, 0, 0.08)' }}
            >
              <div ref={resultsRef} className="ext-stackedFadeIns h-100%">
                <div
                  className=""
                  data-active={true}
                  style={
                    !showCalculator && !formIsComplete
                      ? {
                        filter: 'blur(0px)',
                        height: '600px',
                      }
                      : {}
                  }
                >
                  <div
                    className={`flex flex-col bg-white p-20 sm:p-24 md:p-32 rounded-12 ${
                      renderedValues?.selectedCountry &&
                      renderedValues?.selectedAnnualIncome > 0
                        ? ''
                        : 'md:min-h-725'
                    }`}
                  >
                    <div
                      className={`flex-1 flex flex-col ${!showCalculator && !formIsComplete ? 'opacity-0' : ''}`}
                    >
                      <div className="bg-tertiary-gray1 p-4 flex rounded-4 mb-48">
                        <div
                          className="w-50% bg-white absolute h-64 rounded-3 transition-all duration-200"
                          style={{
                            left: renderedValues.isAnnual
                              ? 'calc(0% + 4px)'
                              : 'calc(50%)',
                            width: 'calc(50% - 4px)',
                          }}
                        />

                        <div
                          className="flex w-50% justify-center items-center h-64 cursor-pointer"
                          onClick={() => calcRenderedValues(true)}
                        >
                          <p
                            className={`is-style-overline font-medium ${
                              renderedValues.isAnnual
                                ? 'text-plum'
                                : 'text-plum/70'
                            }`}
                          >
                            Annual
                          </p>
                        </div>

                        <div
                          className="flex w-50% justify-center items-center h-64 cursor-pointer"
                          onClick={() => calcRenderedValues(false)}
                        >
                          <p
                            className={`is-style-overline font-medium ${
                              renderedValues.isAnnual
                                ? 'text-plum/70'
                                : 'text-plum'
                            }`}
                          >
                            Monthly
                          </p>
                        </div>
                      </div>
                      {renderedValues?.selectedCountry &&
                      renderedValues?.selectedAnnualIncome > 0 ? (
                          <div className="w-100% mb-48">
                            <div className="pb-16 border-b-1 border-tertiary-gray2 flex  flex-row sm:items-center justify-between ">
                              <p className="text-primary-plum opacity-70 is-style-p1  mr-8">
                                Gross{' '}
                                {renderedValues.isAnnual ? 'Annual' : 'Monthly'}{' '}
                                Salary
                              </p>
                              <h4 className="flex shrink-0 text-plum-800">
                                {renderedValues.currencySign}
                                {' '}
                                {renderedValues.isAnnual
                                  ? formatCost(
                                    renderedValues.selectedAnnualIncome,
                                    formIsComplete,
                                    selectedCountry || null
                                  )
                                  : formatCost(
                                    renderedValues.selectedAnnualIncome / 12,
                                    formIsComplete,
                                    selectedCountry || null
                                  )}
                              </h4>
                            </div>

                            <div className="pt-16 mb-20 sm:mb-24 md:mb-32">
                              <div className="flex justify-between flex-row sm:items-center">
                                <p className="text-primary-plum opacity-70 is-style-p1 mr-8">
                                  {renderedValues.isAnnual ? 'Annual' : 'Monthly'}{' '}
                                  Employer Cost
                                </p>
                                <h4 className="flex shrink-0 text-plum-800">
                                  {renderedValues.currencySign}
                                  {' '}
                                  {formatCost(
                                    renderedValues.employerCosts,
                                    formIsComplete,
                                    selectedCountry || null
                                  )}
                                </h4>
                              </div>
                              {lineItemsToRender.length > 0 ? (
                                <div
                                  className={`duration-200 max-h-0 transition-margin-top mt-0 overflow-hidden ${
                                    showLineItems
                                      ? 'active:max-h-1950 active:mt-8'
                                      : ''
                                  }`}
                                >
                                  <div className="sm:p-24 md:p-20 p-20 mt-24 bg-tertiary-gray1">
                                    {lineItemsToRender.map((item, i) => {
                                      const result =
                                      renderedValues?.lineItemsTotals?.[i] || 0;
                                      const itemName = item[0];
                                      const alternateDisclaimerPosition =
                                      item[1]['alternate disclaimer position'];
                                      const disclaimer = item[1].disclaimer;

                                      const theObj = { __html: disclaimer };

                                      const disclaimerComponent = () => {
                                        return <div dangerouslySetInnerHTML={theObj} />;
                                      };

                                      return (
                                        <div
                                          key={`item-${itemName}`}
                                          className={`flex justify-between flex-row sm:items-center border-tertiary-gray2 gap-12 ${
                                            lineItemsToRender.length - 1 > i
                                              ? 'pb-16 mb-16 border-b-1'
                                              : ''
                                          }`}
                                        >
                                          <div className="flex flex-col w-100%">
                                            <p className="is-style-p1 text-black/70">
                                              {slugToTitle(itemName)}
                                            </p>
                                            {disclaimer &&
                                          !alternateDisclaimerPosition ? (
                                                <p className="text-12 leading-14 font-normal text-left">
                                                  {disclaimerComponent()}
                                                </p>
                                              )

                                              : null}
                                          </div>

                                          <div>
                                            <p className="is-style-p1 font-semibold flex shrink-0  text-plum-800 whitespace-nowrap">
                                              {disclaimer &&
                                            alternateDisclaimerPosition ? (
                                                  <span className="text-12 leading-14 font-normal text-right">
                                                    {disclaimerComponent()}
                                                  </span>
                                                ) : (
                                                  <>
                                                    {renderedValues.currencySign}
                                                    {' '}
                                                    {formatCost(
                                                      result,
                                                      formIsComplete,
                                                      selectedCountry || null
                                                    )}
                                                  </>
                                                )}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="w-100% h-100% flex flex-col justify-between">
                              <div className="pb-24 md:pb-0">
                                <p className="mb-8 text-black/70 is-style-p1">
                                  Total{' '}
                                  {renderedValues.isAnnual ? 'Annual' : 'Monthly'}{' '}
                                  Employer Costs
                                </p>
                                <h1 className="mb-24 flex shrink-0 text-plum-800">
                                  {renderedValues.currencySign}
                                  {' '}
                                  {formatCost(
                                    renderedValues.totalEmployerCosts,
                                    formIsComplete,
                                    selectedCountry || null
                                  )}
                                </h1>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="h-100% flex flex-col flex-1 justify-center h-100% mb-48">
                            <div className="flex flex-col gap-8 items-center">
                              <div className="flex items-center justify-center">
                                <div className="h-56 w-56 bg-tertiary-gray1 flex items-center justify-center rounded-12">
                                  <img
                                    className="w-24 h-24"
                                    src="https://rippling2.imgix.net/Add-Circle.svg"
                                  />
                                </div>
                              </div>
                              <p className="text-center is-style-p1 text-plum opacity-70 max-w-375">
                                Enter the hire details in the calculator to view
                                the results displayed here.
                              </p>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div
                  className={`items-center rounded-12 bg-white flex max-h-1950 duration-200 transition-all ${
                    showCalculator ? 'h-0' : 'z-1000'
                  }`}
                  data-active={!showCalculator || undefined}
                >
                  <SectionBuilder blocks={form} />
                </div>
              </div>
            </div>
          </div>

          {renderedValues.employerCosts > 0 && showCalculator ? (
            <div className="display-grid md:grid-cols-[42%_minmax(0,_1fr)] w-100% gap-24 md:gap-x-24 pb-40">
              <a
                className="bg-white p-32 sm:p-40 md:p-48 rounded-12 flex flex-col justify-between gap-20 sm:gap-24 transition-all ease-in-out duration-150"
                href={
                  data[renderedValues.selectedCountry]?.meta.countrypediaLink
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                rel="noreferrer"
                style={{
                  boxShadow: isHovered
                    ? '0px 24px 60px -24px rgba(40, 22, 29, 0.2)'
                    : '',
                }}
                target="_blank"
              >
                <div className="w-32 h-32">
                  <Image
                    attributes={{
                      mediaURL:
                        data[renderedValues.selectedCountry].meta.flag ||
                        data['United States'].meta.flag,
                    }}
                  />
                </div>

                <h3 className="text-plum">
                  Want to learn more about hiring in{' '}
                  <span className="text-plum-400">
                    {renderedValues.selectedCountry}
                  </span>
                  ?
                </h3>

                <div className="bg-tertiary-gray1 w-48 h-48 flex items-center justify-center">
                  <svg
                    fill="none"
                    height="16"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.4467 1.98956L14.4571 7.99997L8.4467 14.0104L7.73959 13.3033L12.5429 8.49997H1.5V7.49997H12.5429L7.73959 2.69667L8.4467 1.98956Z"
                      fill="#502D3C"
                    />
                  </svg>
                </div>
              </a>

              <a
                className="bg-plum-800 rounded-12 overflow-hidden"
                href="/request-demo"
                rel="noreferrer"
                target="_blank"
              >
                <div className="p-32 sm:p-40 md:p-48 z-1000 flex flex-col gap-20 sm:gap-24 md:gap-48">
                  <div className="flex flex-col gap-8">
                    <h3 className="text-white">
                      Interested in hiring in{' '}
                      <span className="text-plum-300">
                        {renderedValues.selectedCountry}
                      </span>
                      ?<br className="hidden sm:block" /> Rippling can help.
                    </h3>
                    <p className="is-style-p1 text-plum-200">
                      Schedule a call with global hiring experts.
                    </p>
                  </div>
                  <div className="flex items-center justify-center bg-yellow max-w-250 py-16 rounded-4">
                    <p className="is-style-p2 text-plum font-medium">
                      See Rippling
                    </p>
                  </div>
                </div>
                <div className="absolute -bottom-4 z-0">
                  <div className="h-100%">
                    <img src="https://rippling2.imgix.net/cta_globe4.png" />
                  </div>
                </div>
              </a>
            </div>
          ) :
            ''
          }
        </div>
      </div>
    </>
  );
};

export default EmployeeCostCalculator;
